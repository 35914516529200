<template src="./template.html"></template>

<script>
export default {
  name: "MainFooter",
  data() {
    return {
      menu: [
        {
          title: "關於宏信",
          link: "/about"
        },
        {
          title: "產品列表",
          link: "/product/list/all"
        },
        {
          title: "影音相簿",
          link: "/video_center"
        },
        {
          title: "最新消息",
          link: "/news/list/1"
        },
        {
          title: "聯絡我們",
          link: "/contact"
        }
      ],
      menu_1_status: false,
      menu_2_status: false
    }
  },
  computed: {
    info() {
      return this.$store.state.company_info
    }
  }
}
</script>