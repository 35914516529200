<template src="./template.html"></template>

<script>
export default {
  name: "MainNav",
  data() {
    return {
      menu_status: false,
      menu: [
        {
          title: "關於宏信",
          link: "/about"
        },
        {
          title: "產品列表",
          link: "/product/list/all/"
        },
        {
          title: "影音相簿",
          link: "/video_center"
        },
        {
          title: "最新消息",
          link: "/news/list/1"
        },
        {
          title: "聯絡我們",
          link: "/contact"
        }
      ],
      key_word: ""
    }
  },
  methods: {
    OpenShopCart() {
      this.$emit("open-shop-cart")
    },
    Search() {
      this.$router.push(`/search?name=${this.key_word}`)
      this.key_word = ""
    }
  }
}
</script>