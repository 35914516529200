<template src="./template.html"></template>

<script>
export default {
  name: "MainDialog",
  methods: {
    Close() {
      this.$store.commit("SetDialog", { status: false, content: "" })
    }
  }
}
</script>