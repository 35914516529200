import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import image_path_transfer from './common/image_url_transfer'
import shop_cart from './common/shop_cart'
import VueCookie from 'vue-cookie';
import axios from 'axios'
import VueAxios from 'vue-axios'
import AxiosPlugin from "./common/axios_plugin"
import VueMeta from 'vue-meta'
import VueJsonLD from 'vue-jsonld'
import VueGtm from 'vue-gtm'
import GA4 from "./common/gtm_methods"

// Tell Vue to use the plugin
Vue.use(VueCookie);
Vue.mixin(GA4)
Vue.use(VueAxios, axios)
Vue.config.productionTip = false

Vue.mixin(image_path_transfer)
Vue.mixin(shop_cart)
Vue.mixin(AxiosPlugin)

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
Vue.use(VueJsonLD)

Vue.use(VueGtm, {
  id: "GTM-TPH3HPQ",
  enabled: true,
  debug: true,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false
})



new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')