export default {
    methods: {
        async GetData(path) {
            return new Promise((resolve) => {
                this.axios({
                    method: 'get',
                    url: process.env.VUE_APP_BASE_API + path,
                }).then((response) => {
                    if (response.data.success == false && response.data.code == 401) {
                        resolve('error token')
                        //錯誤
                    } else if (response.data.code == 200) {
                        resolve(response.data.Data)
                        //success
                    } else {
                        this.$store.commit("SetDialog", {
                            status: true,
                            content: response.data.Msg
                        })
                        resolve(response.data.Data)
                    }
                })
            })
        },
        SendData(path, action = "post", data) {
            return new Promise((resolve) => {
                this.axios({
                    method: action,
                    url: process.env.VUE_APP_BASE_API + path,
                    data: data,
                }).then((response) => {
                    if (response.data.success == false && response.data.code == 401) {
                        resolve('error token')
                        //錯誤
                    } else if (response.data.code == 200) {
                        resolve(response.data.Data)
                        //success
                    } else {
                        this.$store.commit("SetDialog", {
                            status: true,
                            content: response.data.Msg
                        })
                        resolve("error")
                    }
                })
            })
        },
    }
}