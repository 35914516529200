<template>
  <div id="app">
    <MainNav v-on:open-shop-cart="OpenShopCart" />
    <MainShopCart ref="ShopCart" />
    <MainDialog />
    <MainLoading />
    <Beradcrumbs v-if="$route.name != '首頁'" />
    <button class="scroll_top_btn" @click="ScrollTop">
      <span class="material-icons-outlined"> expand_less </span>
    </button>
    <router-view class="router-view" />
    <MainFooter />
  </div>
</template>

<style lang="scss" src="@/assets/css/app.scss"></style>

<script>
import MainNav from "./components/MainNav/index"
import MainFooter from "./components/MainFooter/index"
import Beradcrumbs from "./components/Breadcrumbs/index"
import MainLoading from "./components/MainLoading/index"
import MainDialog from "./components/MainDialog/index"
import MainShopCart from "./components/MainShopCart/index"

export default {
  name: "app",
  components: {
    MainNav,
    MainFooter,
    Beradcrumbs,
    MainLoading,
    MainDialog,
    MainShopCart
  },
  data() {
    return {}
  },
  methods: {
    ScrollTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    OpenShopCart() {
      this.$refs.ShopCart.ToggleOpen()
    }
  },
  mounted() {
    if (this.$store.state.category.length <= 0 && this.$store.state.products.length <= 0) {
      this.$store.dispatch("GetCategory")
      this.$store.dispatch("GetSeries")
      this.$store.dispatch("GetProducts")
      this.$store.dispatch("GetCompanyInfo")
      this.GetShopCart()
    }
  },
  watch: {
    async "$route"() {
      document.getElementById("app").classList.remove("lock")
      if (this.$store.state.category.length <= 0 && this.$store.state.products.length <= 0) {
        this.$store.dispatch("GetCategory")
        this.$store.dispatch("GetSeries")
        this.$store.dispatch("GetProducts")
        this.$store.dispatch("GetCompanyInfo")
        this.GetShopCart()
      }
    }
  },
}
</script>