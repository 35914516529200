var dialog_show = null
export default {
    methods: {
        AddToShopCart(item) {
            let now_shop_cart = JSON.parse(this.$cookie.get("shop_cart"))

            if (now_shop_cart == null) {
                now_shop_cart = [item]
                this.ToggleDialog()
            } else {
                if (now_shop_cart.indexOf(item) == -1) {
                    now_shop_cart.push(item)
                    this.ToggleDialog()
                }
            }
            this.$store.commit("SetShopCart", now_shop_cart)
            this.$cookie.delete('shop_cart');
            this.$cookie.set("shop_cart", JSON.stringify(now_shop_cart))
        },
        DeleteShopCart(id) {
            let now_shop_cart = JSON.parse(this.$cookie.get("shop_cart"))
            let index = now_shop_cart.indexOf(id)
            now_shop_cart.splice(index, 1)

            this.$store.commit("SetShopCart", now_shop_cart)
            this.$cookie.delete('shop_cart');
            this.$cookie.set("shop_cart", JSON.stringify(now_shop_cart))
        },
        GetShopCart() {
            let now_shop_cart = JSON.parse(this.$cookie.get("shop_cart"))
            this.$store.commit("SetShopCart", now_shop_cart)
        },
        ToggleDialog() {
            if (dialog_show == null) {
                this.$store.commit("SetShopCartDialog", true)
                dialog_show = setTimeout(() => {
                    this.$store.commit("SetShopCartDialog", false)
                }, 2000);
            } else {
                this.$store.commit("SetShopCartDialog", false)
                clearTimeout(dialog_show)
                this.$store.commit("SetShopCartDialog", true)
                dialog_show = setTimeout(() => {
                    this.$store.commit("SetShopCartDialog", false)
                }, 2000);
            }
        }
    },
}