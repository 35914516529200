<template src="./template.html"></template>

<script>
export default {
  name: "MainShopCart",
  data() {
    return {
      open: false,
    }
  },
  methods: {
    ToggleOpen() {
      this.open = !this.open
    },
    GetProductDetial(id) {
      let product = this.products.filter(item => item.ProductID == id)
      return product.length > 0 ? product[0] : []
    },
    GetCategoryDetail(product_id) {
      if (this.series.length > 0) {
        let product = this.GetProductDetial(product_id)
        let series = this.series.filter(item => item.ProductDirID == product.ProductDirID)
        return series.length > 0 ? series[0] : []
      }
      else {
        return []
      }
    },
    SendOrder() {
      if (this.$route.name != "商品詢價") {
        this.$router.push("/send_order")
      }
      this.open = false
    },
    ToggleGA(id) {
      this.GA_RemoveFromCart(this.products.filter(item => item.ProductID == id)[0])
    }
  },
  computed: {
    now_shop_cart() {
      return this.$store.state.shop_cart
    },
    products() {
      return this.$store.state.products
    },
    series() {
      return this.$store.state.series
    },
  },
  filters: {
    wordbreak(val) {
      let word = ""
      let count = 0
      let max_count = 14

      if (window.innerWidth <= 640) {
        max_count = 20
      }
      for (let i = 0; i < val.length; i++) {
        let tmp_count = count
        let tmp_word = word
        // eslint-disable-next-line no-control-regex
        if (val[i].match(/[\u0000-\u00ff]/g)) {
          count += 1
          word += val[i]
        }
        else if (val[i].match(/[\u4e00-\u9fa5]/g)) {
          count += 2
          word += val[i]
        }
        else if (val[i].match(/[\uff00-\uffff]/g)) {
          count += 2
          word += val[i]
        }

        if (tmp_count < max_count && count > max_count) {
          word = tmp_word
          break
        }
        else if (count == max_count) {
          break
        }
      }
      if (word == val) {
        return word
      }
      else {
        return word + "..."
      }
    },

  }
}
</script>