import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    dialog: {
      status: false,
      content: ""
    },
    products: [],
    category: [],
    series: [],
    shop_cart: [],
    company_info: {
      company_address: "",
      company_capital: "",
      company_date: "",
      company_email: "",
      company_google_map: "",
      company_line_id: "",
      company_name: "",
      company_phone: "",
      company_time: "",
    },
    shop_cart_dialog: false
  },
  mutations: {
    SetLoading(state, action) {
      state.loading = action
    },
    SetDialog(state, dialog) {
      state.dialog.status = dialog.status
      state.dialog.content = dialog.content
    },
    SetProducts(state, products) {
      state.products = products
    },
    SetCategory(state, category) {
      state.category = category
    },
    SetSeries(state, series) {
      state.series = series
    },
    SetCompanyInfo(state, info) {
      state.company_info = info
    },
    SetShopCart(state, shop_cart) {
      state.shop_cart = shop_cart
    },
    SetShopCartDialog(state, action) {
      state.shop_cart_dialog = action
    }
  },
  actions: {
    async GetProducts({
      commit
    }) {
      commit("SetLoading", true)
      return new Promise((resolve) => {
        axios({
          method: 'get',
          url: process.env.VUE_APP_BASE_API + "/product",
        }).then((response) => {
          commit("SetLoading", false)
          if (response.data.code == 200) {
            commit("SetProducts", response.data.Data)
            resolve(response.data.Data)
          } else {
            this.$store.commit("SetDialog", {
              status: true,
              content: response.data.msg
            })
            resolve(response.data.Data)
          }
        })
      })
    },
    async GetCategory({
      commit
    }) {
      commit("SetLoading", true)
      return new Promise((resolve) => {
        axios({
          method: 'get',
          url: process.env.VUE_APP_BASE_API + "/product/dir2",
        }).then((response) => {
          commit("SetLoading", false)
          if (response.data.code == 200) {
            commit("SetCategory", response.data.Data)
            resolve(response.data.Data)
          } else {
            this.$store.commit("SetDialog", {
              status: true,
              content: response.data.msg
            })
            resolve(response.data.Data)
          }
        })
      })
    },
    async GetSeries({
      commit
    }) {
      commit("SetLoading", true)
      return new Promise((resolve) => {
        axios({
          method: 'get',
          url: process.env.VUE_APP_BASE_API + "/product/dir",
        }).then((response) => {
          commit("SetLoading", false)
          if (response.data.code == 200) {
            commit("SetSeries", response.data.Data)
            resolve(response.data.Data)
          } else {
            this.$store.commit("SetDialog", {
              status: true,
              content: response.data.msg
            })
            resolve(response.data.Data)
          }
        })
      })
    },

    async GetCompanyInfo({
      commit
    }) {
      commit("SetLoading", true)
      return new Promise((resolve) => {
        axios({
          method: 'post',
          url: process.env.VUE_APP_BASE_API + "/column",
          data: {
            Data: ["company_address",
              "company_capital",
              "company_date",
              "company_email",
              "company_google_map",
              "company_line_id",
              "company_name",
              "company_phone",
              "company_time",
            ]
          }
        }).then((response) => {
          commit("SetLoading", false)
          if (response.data.code == 200) {
            commit("SetCompanyInfo", response.data.Data)
            resolve(response.data.Data)
          } else {
            this.$store.commit("SetDialog", {
              status: true,
              content: response.data.msg
            })
            resolve(response.data.Data)
          }
        })
      })
    }
  },
  modules: {}
})