<template src="./template.html"></template>
    
<script>
export default {
  name: "HomeProductCategory",
  data() {
    return {

    }
  },
  methods: {
    GetFirstSeries(id) {
      let now_series = this.series.filter(item => { return item.ProductDir2ID == id })
      return now_series.length <= 0 ? -1 : now_series[0].ProductDirID
    }
  },
  computed: {
    category() {
      return this.$store.state.category
    },
    series() {
      return this.$store.state.series
    }
  },
  created() {
  }
}
</script>