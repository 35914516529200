<template src="./template.html"></template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  name: "HomeHotProducts",
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 5,
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: false
        },
      },
      pad_swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: false
        },
      },
      mobile_swiperOption: {
        slidesPerView: 2,
        slidesPerColumn: 2,
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: false
        },
      },
    }
  },
  methods: {
    GetSeriesTitle(id) {
      let now_series = this.series.filter(item => item.ProductDirID == id)
      return now_series.length <= 0 ? "" : now_series[0].Title
    }
  },
  filters: {
    wordbreak(val) {
      let word = ""
      let count = 0
      let max_count = 14

      if (window.innerWidth <= 640) {
        max_count = 20
      }
      for (let i = 0; i < val.length; i++) {
        let tmp_count = count
        let tmp_word = word
        // eslint-disable-next-line no-control-regex
        if (val[i].match(/[\u0000-\u00ff]/g)) {
          count += 1
          word += val[i]
        }
        else if (val[i].match(/[\u4e00-\u9fa5]/g)) {
          count += 2
          word += val[i]
        }
        else if (val[i].match(/[\uff00-\uffff]/g)) {
          count += 2
          word += val[i]
        }

        if (tmp_count < max_count && count > max_count) {
          word = tmp_word
          break
        }
        else if (count == max_count) {
          break
        }
      }
      if (word == val) {
        return word
      }
      else {
        return word + "..."
      }
    }
  },
  computed: {
    products() {
      return this.$store.state.products.filter(item => item.TagHot == "Y")
    },
    series() {
      return this.$store.state.series
    }
  }
}
</script>