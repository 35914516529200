<template>
  <div id="Home">
    <Carousel :carousel="carousel" />
    <Category />
    <HotProducts />
    <About />
  </div>
</template>

<script>
import { GetMetaData } from '@/common/meta.js';
import Carousel from '../components/Home/Carousel/index';
import Category from '../components/Home/Category/index';
import HotProducts from '../components/Home/HotProducts/index';
import About from '../components/Home/About/index';
export default {
  name: 'Home',
  components: {
    Carousel,
    Category,
    HotProducts,
    About,
  },
  data() {
    return {
      about_content: '',
      carousel: [],
      meta_carousel: [],
      meta_data: null,
    };
  },
  methods: {
    async LoadAboutData() {
      this.$store.commit('SetLoading', true);
      let response = await this.SendData('/column', 'post', {
        Data: ['about_content'],
      });
      this.$store.commit('SetLoading', false);
      if (response != 'error') {
        this.about_content = response.about_content.Content;
        this.meta_data = GetMetaData(
          '專業修護 x 工具五金',
          this.about_content.replace(/<[^>]+>/g, ''),
          ''
        );
        this.$nextTick(() => {
          window.prerenderReady = true;
        });
      }
    },
    async LoadCarouselData() {
      this.$store.commit('SetLoading', true);
      let response = await this.GetData('/carousel');
      this.$store.commit('SetLoading', false);
      if (response != 'error') {
        this.carousel = response;
        this.meta_carousel.push('https://www.hongsyn.com/img/share.jpg');
        this.carousel.forEach((item) => {
          this.meta_carousel.push(this.GetFullPath(item.Image));
          this.meta_carousel.push(this.GetFullPath(item.Image2));
        });
      }
    },
  },
  created() {
    this.LoadAboutData();
    this.LoadCarouselData();
  },
  metaInfo() {
    return this.meta_data;
  },
  jsonld() {
    return {
      '@context': 'https://schema.org',
      '@type': 'Store',
      image: this.meta_carousel,
      '@id': 'https://www.hongsyn.com/',
      name: '宏信五金行',
      address: {
        '@type': 'PostalAddress',
        streetAddress: '東區東橋里建德街172號1樓',
        addressLocality: '台中市',
        addressRegion: 'TC',
        postalCode: '401',
        addressCountry: 'TW',
      },
      review: {
        '@type': 'Review',
        reviewRating: {
          '@type': 'Rating',
          ratingValue: '4',
          bestRating: '5',
        },
        author: {
          '@type': 'Person',
          name: '林泳欣',
        },
      },
      geo: {
        '@type': 'GeoCoordinates',
        latitude: 24.129345150021532,
        longitude: 120.69205199745873,
      },
      url: 'https://www.hongsyn.com/',
      telephone: '+886422821768',
      servesCuisine: 'Taiwan',
      openingHoursSpecification: [
        {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
          opens: '08:30',
          closes: '17:30',
        },
      ],
    };
  },
};
</script>
