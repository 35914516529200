<template src="./template.html"></template>

<script>
export default {
  name: "Breadcrumbs",
  data() {
    return {
      router_list: [],
    }
  },
  methods: {
    GetSeries() {
      if (this.$route.params.type == "all") {
        return "全部商品"
      }
      else if (this.$route.params.type == "hot") {
        return "熱門商品"
      }
      else if (this.$route.params.type == "news") {
        return "最新商品"
      }
      else {
        let tmp_series = this.$store.state.series.filter(item => item.ProductDirID == this.$route.params.type)
        return tmp_series.length > 0 ? tmp_series[0].Title : ""
      }
    },
    GetProduct() {
      let tmp_product = this.$store.state.products.filter(item => item.ProductID == this.$route.params.id)
      return tmp_product.length > 0 ? tmp_product[0].Title : ""
    },
    GetProductSeries() {
      if (this.GetProduct() != '') {
        let tmp_product = this.$store.state.products.filter(item => item.ProductID == this.$route.params.id)[0]
        let tmp_series = this.$store.state.series.filter(item => item.ProductDirID == tmp_product.ProductDirID)
        return tmp_series.length > 0 ? [tmp_series[0].ProductDirID, tmp_series[0].Title] : ["", ""]
      }
      else {
        return ["", ""]
      }
    }
  },
  watch: {
    '$route'() {
      this.router_list = this.$route.matched
    }
  },
  computed: {
  },
  mounted() {
    this.router_list = this.$route.matched
  }
}
</script>