var default_meta = {
  title: '專業修護 x 工具五金',
  content:
    '成立於光復後，原名清信五金行，民國61年遷至建國市場專精軸承、水電、機械工具，後分為軸承部和工具部，我們工具部自民國84年搬到建德街後改名為宏信五金行，希望以正派經營與合理的利潤與客人們共榮共存，繼續經營汽車工具、機車工具、機械工具、量測儀器、切削工具、軸承等。',
  image: 'https://www.hongsyn.com/img/share.jpg',
}

export function GetMetaData(title, content, image) {
  title = title == '' ? default_meta.title : title
  return {
    title: '宏信五金行 | ' + title,
    meta: [
      {
        property: 'og:description',
        content: content == '' ? default_meta.content : content,
        vmid: 'og:description',
      },
      {
        property: 'twitter:description',
        content: content == '' ? default_meta.content : content,
        vmid: 'twitter:description',
      },
      {
        property: 'description',
        content: content == '' ? default_meta.content : content,
        vmid: 'description',
      },
      {
        property: 'og:image',
        content: image == '' ? default_meta.image : image,
        vmid: 'og:image',
      },
      {
        property: 'apple-mobile-web-app-title',
        content: '宏信五金行 | ' + title,
        vmid: 'apple-mobile-web-app-title',
      },
      {
        property: 'application-name',
        content: '宏信五金行 | ' + title,
        vmid: 'application-name',
      },
      {
        property: 'og:site_name',
        content: '宏信五金行 | ' + title,
        vmid: 'og:site_name',
      },
      {
        property: 'og:type',
        content: 'website',
        vmid: 'og:type',
      },
      {
        property: 'og:locale',
        content: 'zh_tw',
        vmid: 'og:locale',
      },
    ],
  }
}
